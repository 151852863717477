<!--
 * @Author: duanxin
 * @Date: 2023-07-26 10:08:58
 * @LastEditors: lijing
 * @LastEditTime: 2024-01-16 17:39:37
 * @Description:
-->
<template>
  <!-- 新增业务配置弹框 -->
  <el-dialog
    class="config-dialog-box"
    :title="title"
    :width= 'formData.itemCode == 20 && batchList.length ? "840px" : "584px"'
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form :model="formData" label-width="110px" ref="addForm" :rules="rule">
      <el-form-item label="城市" prop="areaId" key="areaId">
        <a-select
          filterable
          v-model="formData.areaId"
          :data="areaList"
          label="areaName"
          value="areaId"
          placeholder="全部"
          modifier="trim"
          :disabled="isEdit || !checkParams({ itemCode: [1, 6, 7, 12, 13, 14] })"
        >
          <el-option label="全部" value="0"></el-option>
        </a-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId" key="operationId">
        <a-select
          filterable
          v-model="formData.operationId"
          :data="tenantList"
          label="operationName"
          value="operationId"
          placeholder="全部"
          modifier="trim"
          :disabled="isEdit || !checkParams({ itemCode: [1, 6, 7, 12, 13, 14] })"
        >
          <el-option label="全部" value="0"></el-option>
        </a-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Configuration_items')" prop="itemCode" key="itemCode">
        <a-select
          v-model="formData.itemCode"
          :data="getConfigDetailList"
          label="identifier"
          value="code"
          :disabled="isEdit"
          @change="configChange"
        />
      </el-form-item>
      <el-form-item v-if="checkParams({ itemCode: [1, 6, 7, 8, 11, 12] }, 'some')">
        <a-radio v-model="formData.parameterValue" :disabled="isDetail" :radioList="radioList" />
      </el-form-item>
      <el-form-item v-if="checkParams({ itemCode: 7, parameterValue: 0 })">
        <a-radio
          v-model="exitType"
          :disabled="isDetail"
          :radioList="[
            { label: '0', value: '自动出场' },
            { label: '1', value: 'PDA确认出场' },
          ]"
        />
      </el-form-item>
      <el-form-item class="pda-wrapper" v-if="checkParams({ itemCode: 13 })">
        <div class="item-wrap">
          <div>
            <el-checkbox v-model="pdaInfo.warning1" :disabled="true">一次警示</el-checkbox>
            <span
              >地磁信号已上报：
              <a-input
                style="width: 80px"
                v-model="pdaInfo.minutes1"
                valueType="plus"
                :range="[0, 240]"
                :disabled="isDetail"
              />
              分钟后变色警示，取值范围 [ 0，240 ]
            </span>
          </div>
          <div>
            <el-checkbox v-model="pdaInfo.warning2" :disabled="true">二次警示</el-checkbox>
            <span
              >地磁信号已上报：
              <a-input
                style="width: 80px"
                v-model="pdaInfo.minutes2"
                valueType="plus"
                :range="[0, 240]"
                :disabled="isDetail"
              />
              分钟后变色警示，取值范围 [ 0，240 ]
            </span>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        :label="itemShow | formatLabel"
        class="parameter-value-label"
        v-if="!checkParams({ itemCode: ['', 1, 6, 7, 8, 11, 12, 13, 20] })"
        prop="parameterValue"
        key="parameterValue"
      >
        <!-- 一键离场时间 -->
        <template v-if="checkParams({ itemCode: 16 })">
          <!-- 根据接口具体参数修改此参数 parameterValue -->
          <el-time-picker v-model="formData.parameterValue" format="HH:mm" value-format="HH:mm" />
        </template>
        <template v-else>
          <a-input
            :disabled="isDetail"
            v-bind="{ ...paramConfig }"
            v-model="formData.parameterValue"
          />
        </template>
        <span v-if="checkParams({ itemCode: [2, 3, 4, 5, 9, 10, 14, 15] }, 'some')">{{
          getContent
        }}</span>
      </el-form-item>
      <el-form-item v-if="formData.itemCode == 20" label="批次名称">
        <el-input
          maxlength="8"
          placeholder="请输入批次名称"
          v-model="batchName"
        ></el-input>
        <div class="add-btn"><el-button class="el-plain" @click="addBatch" icon="el-icon-plus">{{ $t('button.addto') }}</el-button></div>
        <div v-if="batchList.length" class="batch-list">
          <ul>
            <li v-for="(item, index) in batchList" :key="index">
              {{ item }}
              <el-button class="el-plain" icon="el-icon-minus" @click="delBatch(index)">删除</el-button>
            </li>
          </ul>
        </div>
      </el-form-item>
      <el-form-item v-if="formData.itemCode">
        <!-- 欠费业务限制 -->
        <div class="bussiness-box" v-if="checkParams({ itemCode: 15 })">
          <div class="bussiness-box-item" v-for="(item, key) in arrearsBussiness" :key="key">
            <el-checkbox v-model="item.checked">
              <span class="checkbox-title">{{ item.identifier }}</span>
            </el-checkbox>
            <span class="tip-item" v-if="item.checked">{{ item.desc }}</span>
          </div>
        </div>
        <span v-else class="content-tip" v-html="contentTip"></span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="!isDetail">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveAddBlackFn">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { keys, isArray, isNaN, get, reduce } from "lodash";
// import { formatTime } from '@/common/js/utils'
import { aRadio, aSelect, aInput } from "@/components/bussinessArrears";
const baseUrl = "/acb/2.0/businessParameterConfiguration";
export default {
  name: "addConfigDialog",
  components: {
    aRadio,
    aSelect,
    aInput,
  },
  model: {
    prop: "pdaInfo",
    event: "init",
  },
  props: {
    pdaInfo: Object, // PDA相应强提醒配置项
    isAdd: Boolean, // 新增
    isDetail: Boolean, // 详情
    isEdit: Boolean, // 编辑
    exitParkType: String, // exitType
    rule: Object, // form rule
    showDialog: Boolean,
    getConfigDetailList: Array,
    areaList: Array,
    tenantList: Array,
    formData: {
      default: () => {
        return {
          operationId: "",
          areaId: "",
          itemCode: "",
          parameterValue: "",
          parameterDescription: "",
        };
      },
    },
    // 欠费业务限制操作
    arrearsBussiness: {
      default: () => [],
    },
  },
  data() {
    return {
      itemShow: "",
      batchName: "",
      batchList: [],
    };
  },
  filters: {
    formatLabel(code) {
      switch (code) {
        case 14:
          return "平单系统时效";
        case 15:
          return "限制金额";
        case 16:
          return "一键离场时间";
        default:
          return "参数";
      }
    },
  },
  watch: {
    formData: {
      handler(data) {
        this.itemShow = data.itemCode;
        this.batchList = data.parameterValue ? data.parameterValue.split(",") : [];
      },
      immediate: true,
    },
  },
  computed: {
    // 参数输入框配置信息
    paramConfig() {
      let code = Number(this.itemShow);
      let config = {
        valueType: "intPlus",
        fixed: 0,
        range: [],
      };
      // 4 短信成本
      // 5 只能外呼成本
      // 15 欠费业务限制
      // 16 一键离场
      if ([4, 5, 15, 16].includes(Number(code))) {
        config.fixed = 2;
        config.valueType = "plus";
      }
      if (code === 14) config.range = [0, 365];
      return config;
    },
    // 配置项提示内容
    contentTip() {
      if (this.isAdd) {
        return get(this.tips, this.itemShow, "");
      }
      return get(this.formData, "parameterDescription", "");
    },
    tips() {
      return reduce(
        this.getConfigDetailList,
        (pre, { code, desc }) => {
          pre[code] = desc;
          return pre;
        },
        {}
      );
    },
    title() {
      const { isDetail, isEdit } = this;
      return isDetail ? "查看业务配置" : isEdit ? "编辑业务配置" : "新增业务配置";
    },
    getContent() {
      const {
        formData: { itemCode },
      } = this;
      return {
        2: "小时",
        3: "天",
        4: "元/条",
        5: "元/条",
        9: "分钟",
        10: "分钟",
        14: "天",
        15: "元，支持两位小数",
      }[itemCode];
    },
    radioList() {
      let {
        formData: { itemCode: code },
        valToNum,
      } = this;
      code = valToNum(code);
      switch (code) {
        case 8:
          return [
            { label: "1", value: "自动补单" },
            { label: "0", value: "人工补单" },
          ];
        case 11:
          return [
            { label: "1", value: "自动退款" },
            { label: "0", value: "人工退款" },
          ];
        case 12:
          return [
            { label: "0", value: "第三方本地计费传" },
            { label: "1", value: "爱泊车服务端计费" },
          ];
        case 1:
        case 7:
          return [
            { label: "0", value: "取信地磁" },
            { label: "1", value: "PDA审核" },
          ];
        case 6:
          return [
            { label: "0", value: "否" },
            { label: "1", value: "是" },
          ];
        default:
          return [];
      }
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(state) {
        this.$emit("update:showDialog", state);
      },
    },
    exitType: {
      get() {
        return this.exitParkType;
      },
      set(type) {
        this.$emit("update:exitParkType", type);
      },
    },
  },
  methods: {
    addBatch() {
      this.batchName && this.batchList.push(this.batchName);
      this.batchName = "";
    },
    delBatch(index) {
      if (this.batchList.length == 1) {
        this.$alert("请至少保留一项，本条不可删除", "提示");
        return;
      };
      this.batchList.splice(index, 1);
    },
    handleClose() {
      let addForm = this.$refs.addForm;
      addForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 数字字符转为数字
     * @param {数字字符串} value
     */
    valToNum(value) {
      let _toNumber = parseInt(value);
      if (isNaN(_toNumber)) _toNumber = value;
      return _toNumber;
    },
    /**
     * 校验参数
     * @param {需要匹配的参数} param
     */
    checkParams(param, type = "every") {
      const { formData: data, valToNum } = this;
      let res = keys(param)[type]((key) => {
        let value = param[key];
        let dataVal = valToNum(data[key]);
        if (isArray(value)) {
          return value.includes(dataVal);
        }
        return value === dataVal;
      });
      return res;
    },
    // 切换配置项展示对应的参数描述
    configChange(code) {
      code = Number(code);
      this.$refs.addForm.clearValidate();
      let value = ""; // 设置配置项时参数置空
      if (this.formData.parameterValue) {
        // this.formData.parameterValue = ""; // 切换配置项时参数置空
        this.exitType = "";
      }
      // 入场地磁、出场地磁、发票冲红、差账处理、重复支付默认勾选
      if ([1, 7, 8, 11].includes(code)) {
        value = "1";
      } else if (code === 6) {
        value = "0";
      }
      this.formData.parameterValue = value;
      let required = true;
      if (![1, 6, 7, 12, 13, 14].includes(code)) {
        required = false;
        // this.rule.operationId[0].required = false;
        // this.rule.areaId[0].required = false;
        this.formData.areaId = "";
        this.formData.operationId = "";
      } else {
        // this.rule.operationId[0].required = true;
        // this.rule.areaId[0].required = true;
      }
      this.rule.operationId[0].required = required;
      this.rule.areaId[0].required = required;
      if (code == 13) {
        this.$emit("init", {
          warning1: true,
          warning2: true,
          minutes1: "",
          minutes2: "",
        });
      }
      this.itemShow = code;
    },
    saveAddBlackFn() {
      const {
        formData: {
          operationId,
          areaId,
          itemCode,
          parameterValue,
          parameterDescription,
          businessParameterConfigurationId,
        },
        pdaInfo: { warning1, warning2, minutes1, minutes2 },
        exitType,
      } = this;
      if (itemCode == 7 && parameterValue == 0) {
        if (exitType === "") {
          this.$alert("请选择出场模式", "提示");
          return;
        }
      } else {
        this.exitType = "";
      }
      if (itemCode == 12 && parameterValue == "") {
        this.$alert("请选择计费模式", "提示");
        return;
      }
      let tempPda = {};
      if (itemCode == 13) {
        if (minutes1 === "" && minutes2 === "") {
          this.$alert("至少输入一次警示时长", "提示");
          return;
        } else if (minutes1 === minutes2) {
          this.$alert("两次警示时长不能相等，请修改。", "提示");
          return;
        } else if (minutes1 > minutes2 && minutes2 != "") {
          this.$alert("二次警示不能小于一次警示");
          return;
        }
        if (warning1) {
          tempPda[1] = minutes1;
        } else {
          tempPda[-1] = -1;
        }
        if (warning2) {
          tempPda[2] = minutes2;
        } else {
          tempPda[-1] = -1;
        }
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let url = baseUrl + (this.isEdit ? "/update" : "/add");
          const data = {
            parameterValue: exitType ? parameterValue + "," + exitType : parameterValue,
          };
          if (this.isEdit) {
            Object.assign(data, { businessParameterConfigurationId });
          } else {
            Object.assign(data, {
              operationId,
              areaId,
              itemCode,
              parameterDescription,
            });
          }
          if (itemCode == 13) {
            data.parameterValue = JSON.stringify(tempPda).slice(1, -1).replace(/[""]/g, "");
          };
          if (itemCode == 20) {
            data.parameterValue = this.batchList.join();
          }
          this.$axios
            .post(url, {
              data: data,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$parent.search();
                this.$message({
                  type: "success",
                  message: res.desc,
                });
              } else {
                this.$alert(res.desc, "提示");
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style lang="stylus" scoped>
.config-dialog-box
  /deep/.el-input {
    width: 320px;
  }
  .bussiness-limit
    display flex
    flex-wrap wrap
    align-items center
    .amount-limit
    .out-park-time
      display flex
      align-items center
      .el-input
        max-width 193px
    .out-park-tip-box
      line-height 40px
  .bussiness-box
    .bussiness-box-item
      line-height 24px
      margin 8px 0 16px
      .el-checkbox
        ::v-deep .el-checkbox__label
          color inherit !important
      .tip-item
        margin-left 8px
  .el-form-item
    ::v-deep
      .content-tip
        white-space pre-wrap
    .batch-list
      width 362px
      height 268px
      padding: 20px 20px 0
      border 1px solid #E9E9E9
      box-sizing border-box
      border-radius 4px
      position absolute
      top -180px
      left 334px
      overflow auto
      ul
        li
          line-height 32px
          height 32px
          background #f2f3f5
          border-radius 2px
          width 240px
          padding 0 12px
          margin-bottom 20px
          box-sizing border-box
          color #86909C
          /deep/.el-plain
            position absolute
            right 16px
            padding 7px 8px
            color #FF0000
            border 1px solid #FF0000
            background #fff
    .add-btn
      margin-top 8px
      /deep/.el-plain
        padding 7px 8px
        color #0F6EFF
        border 1px solid #0F6EFF
        background #fff
</style>

<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Configuration_items')" prop="itemCode">
                <a-select
                  filterable
                  label="identifier"
                  value="code"
                  v-model="formInline.itemCode"
                  :data="getConfigList"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button type="primary" @click="handleClick('search')">{{ $t('button.search') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-plus" @click="handleClick('add')">{{ $t('button.addto') }}</el-button>
              <el-button v-if="show351" type="primary" @click="handleClick('config')"
                >配置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData">
          <el-table-column
            v-for="{
              label,
              prop,
              width,
              formatter,
              align = 'center',
              headerAlign = 'center',
              className = '',
            } in tableCols"
            :header-align="headerAlign"
            :align="align"
            :prop="prop"
            :label="label"
            :min-width="width"
            :key="prop"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div v-if="prop == 'parameterValue'" :class="className" v-html="formatter(row)"></div>
              <div :class="className" v-else v-html="row[prop]"></div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" align="center"
            ><template slot-scope="{ row }">
              <el-dropdown @command="handleCommand($event, row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="{ prop, label } in options"
                    :key="prop"
                    :command="prop"
                    >{{ label }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template></el-table-column
          >
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- 业务配置弹框 -->
      <addConfigDialog
        v-model="pdaInfo"
        :isDetail="isDetail"
        :isAdd="isAdd"
        :isEdit="isEdit"
        :rule="rule"
        :exitParkType.sync="exitType"
        :showDialog.sync="dialogVisible"
        :formData="addformInline"
        :arrearsBussiness="arrearsBussiness"
        :areaList="areaList"
        :tenantList="tenantList"
        :getConfigDetailList="getConfigDetailList"
      />
      <!-- 配置显示弹窗 -->
      <configDialog :showDialog.sync="configVisible" :formData="configFormData" />
    </div>
  </div>
</template>

<script>
import { debounce, keys, forEach, get } from "lodash";
import { setIndex } from "@/common/js/public.js";
import addConfigDialog from "./components/addConfigDialog.vue";
import configDialog from "./components/configDialog.vue";
import { aSelect } from "@/components/bussinessArrears";
const baseUrl = "/acb/2.0/businessParameterConfiguration";
export default {
  name: "staggeredOrder",
  components: {
    addConfigDialog,
    configDialog,
    aSelect
  },
  data() {
    const parameterValue = ({ itemCode: code, parameterValue: value }) => {
      let unit = this.codeKeys[code];
      let data = this.paramInfo[code];
      if (code == 7 && value == 1) {
        return "PDA审核";
      } else if (data) {
        return get(data, value, data[1]);
      } else if (unit) {
        return value + unit;
      } else if (code == 13) {
        let valList = value.split(",");
        let param = "";
        valList.forEach((element) => {
          let [warning, minute] = element.split(":");
          let tpl = minute > 0 ? { 1: "一", 2: "二" }[warning] : "";
          param += (tpl && `${minute}分钟 ${tpl}次警示</br>`) || tpl;
        });
        return param;
      } else {
        return value;
      }
    };
    return {
      show351: false, // 3.5.1开关，待相应接口可调式将相关去掉
      uploadHeader: {
        "Session-Id": localStorage.token,
      },
      dialogVisible: false,
      configVisible: false,
      isDetail: false,
      isEdit: false,
      isAdd: false,
      rowData: {},
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      exitType: "", // 出场模式时选择地磁展示的单选框
      modelvalue1: "",
      rule: {
        operationId: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        itemCode: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        parameterValue: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          // {
          //   validator: parameterSetValue,
          //   trigger: "blur"
          // }
        ],
      },
      options: [
        {
          prop: "a",
          label: "查看",
        },
        {
          prop: "b",
          label: "编辑",
        },
        {
          prop: "c",
          label: "删除",
        },
      ],
      // 列表itemCode
      codeKeys: {
        2: "小时",
        3: "天",
        4: "元/条",
        5: "元/条",
        9: "分钟",
        10: "分钟",
        14: "天",
      },
      // 列表参数
      paramInfo: {
        1: ["取信地磁", "PDA审核"],
        6: ["否", "是"],
        7: { "0,0": "取信地磁,自动出场", 1: "取信地磁,PDA确认出场" },
        8: ["人工补单", "自动补单"],
        11: ["人工退款", "自动退款"],
        12: ["第三方本地计费", "爱泊车服务端计费"],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "60",
        },
        {
          prop: "itemName",
          label: "配置项",
          width: "180",
        },
        {
          prop: "parameterValue",
          label: "参数",
          width: "200",
          formatter: parameterValue,
        },
        {
          prop: "areaName",
          // label: this.$t("list.region"),
          label: this.$t("list.city"),
          width: "80",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "160",
        },
        {
          prop: "parameterDescription",
          label: "参数描述",
          width: "480",
          align: "left",
          className: "b-style",
        },
      ],
      tableData: [],
      page: 1,
      size: 15,
      formInline: {
        operationId: "",
        areaId: "",
        itemCode: "",
      },
      // 欠费业务配置项数据，根据接口返回，此处用假数据代替
      arrearsBussiness: [
        {
          checked: false,
          identifier: "车主端购买、续费限制",
          desc: "提示：“功能限制，系统检测您有欠费，缴清后可享用本功能。”",
        },
        {
          checked: false,
          identifier: "车主端换绑限制",
          desc: "提示：“系统检测您要换帮的车牌有欠费，缴清后可换绑。”",
        },
        {
          checked: false,
          identifier: "后台开卡续费限制",
          desc: "提示：“系统检测XXX车牌有欠费，请车主缴清后再进行该操作。”",
        },
      ],
      configFormData: [
        {
          switchType: false,
          label: {
            ok: "开启地磁模式后，可以配置地磁相关参数",
            cancel: "地磁模式",
          },
          switchList: [
            {
              checked: false,
              label: "入场地磁模式",
            },
            {
              checked: false,
              label: "出场地磁模式",
            },
            {
              checked: false,
              label: "地磁设备离线间隔分钟",
            },
          ],
        },
        {
          switchType: false,
          label: {
            ok: "开启支付结算后，可以配置支付结算相关参数",
            cancel: "支付结算",
          },
          switchList: [
            {
              checked: false,
              label: "差账处理",
            },
            {
              checked: false,
              label: "重复支付",
            },
            {
              checked: false,
              label: "发票自动冲红",
            },
          ],
        },
        {
          switchType: false,
          label: {
            ok: "开启视频设备后，可以配置视频设备相关参数",
            cancel: "视频设备",
          },
          switchList: [
            {
              checked: false,
              label: "视频设备离线间隔分钟",
            },
          ],
        },
        {
          switchType: false,
          label: {
            ok: "开启第三方配置后，可以配置第三方配置相关参数",
            cancel: "第三方配置",
          },
          switchList: [
            {
              checked: false,
              label: "第三方封闭车场计费模式",
            },
          ],
        },
        {
          switchType: false,
          label: {
            ok: "开启PDA配置后，可以配置PDA相关参数",
            cancel: "PDA配置",
          },
          switchList: [
            {
              checked: false,
              label: "PDA响应强提醒",
            },
            {
              checked: false,
              label: "一键离场",
            },
          ],
        },
        {
          switchType: false,
          label: {
            ok: "开启欠费业务配置后，可以配置欠费业务相关参数",
            cancel: "欠费业务配置",
          },
          switchList: [
            {
              checked: false,
              label: "欠费业务限制",
            },
            {
              checked: false,
              label: "追缴启动条件",
            },
            {
              checked: false,
              label: "追缴有效期",
            },
            {
              checked: false,
              label: "短信成本",
            },
            {
              checked: false,
              label: "智能外呼成本",
            },
          ],
        },
      ],
      addformInline: {
        operationId: "",
        areaId: "",
        itemCode: "",
        parameterValue: "",
        parameterDescription: "",
      },
      pdaInfo: {
        warning1: true,
        warning2: true,
        minutes1: "",
        minutes2: "",
      },
      fileList: [],
      detailformInline: {},
      tenantList: [],
      areaList: [],
      getConfigList: [],
      getConfigDetailList: [],
      businessParameterConfigurationId: "",
    };
  },
  computed: {},
  watch: {
    dialogVisible(type) {
      if (!type) {
        this.closeDialogFn();
      }
    },
  },
  methods: {
    handleClick(type) {
      if (type === "search") {
        this.pageNum = 1;
      }
      this[type]();
    },
    /**
     * 商户名称的下拉框
     */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(({ state, value, desc }) => {
        if (state == 0) {
          this.tenantList = value;
        } else {
          this.alertBox(desc);
        }
      });
    },
    /**
     * 城市下拉框
     */
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then(({ state, value, desc }) => {
        if (state == 0) {
          const { depth, areaList } = value;
          if (depth == 3) {
            this.areaList = areaList;
          } else {
            this.streetList = areaList;
          }
        } else {
          this.alertBox(desc);
        }
      });
    },
    alertBox(data) {
      if (typeof data === "object") {
        let { msg, tip = this.$t('pop_up.Tips'), config = {} } = data;
        this.$alert(msg, tip, config);
      } else {
        this.$alert(data, "提示");
      }
    },
    /**
     * 系统配置项列表
     */
    async getConfiguration() {
      try {
        let { state, value, desc } = await this.$axios.get(`${baseUrl}/getConfigurationItems`);
        if (state == 0) {
          // value.push(
          //   // TODO  添加测试片段数据（欠费业务限制、一键离场），假数据，待接口返回扣将对应的code值对应
          //   {
          //     "code": 15,
          //     "identifier": "欠费业务限制",
          //     "desc": ""
          //   },
          //   {
          //     "code": 16,
          //     "identifier": "一键离场",
          //     "desc": "一键离场时间精确到时分，用于夜间免费停车一键将所有车场统一离场。"
          //   }
          // )
          this.getConfigList = [
            {
              code: "",
              identifier: "全部",
            },
            ...value,
          ];
          this.getConfigDetailList = value;
        } else {
          this.alertBox(desc);
        }
      } catch (error) {
        console.log("--getConfiguration--", error);
      }
    },
    /**
     * 详情
     * @param {ID} businessParameterConfigurationId
     */
    detail(businessParameterConfigurationId) {
      this.$axios
        .get(`${baseUrl}/getById`, {
          data: {
            businessParameterConfigurationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.addformInline = res.value;
            this.businessParameterConfigurationId = res.value.businessParameterConfigurationId;
            if (res.value) {
              let item = res.value.itemCode;
              if (item == 7 && res.value.parameterValue != 1) {
                let arr = res.value.parameterValue.split(",");
                for (var i = 0; i < arr.length; i++) {
                  if (arr[i] == 0) {
                    this.addformInline.parameterValue = "0";
                    this.exitType = "0";
                  } else if (arr[i] == 1) {
                    this.exitType = "1";
                  }
                }
              }
              // if (item !== 1 && item !== 6 && item !== 7  && item !== 13 && item !== 14) {
              if (![1, 6, 7, 13, 14].includes(item)) {
                this.rule.operationId[0].required = false;
                this.rule.areaId[0].required = false;
              } else {
                if (res.value.areaName == "全部") {
                  this.addformInline.areaId = "0";
                }
                if (res.value.operationName == "全部") {
                  this.addformInline.operationId = "0";
                }

                this.rule.operationId[0].required = true;
                this.rule.areaId[0].required = true;
              }
              if (item == 13) {
                let valList = res.value.parameterValue.split(",");
                valList.forEach((element) => {
                  let str = element.split(":");
                  if (["1", "2"].includes(str[0])) {
                    this.$set(this.pdaInfo, `warning${str[0]}`, true);
                    this.$set(this.pdaInfo, `minutes${str[0]}`, str[1] < 0 ? "" : str[1]);
                  }
                });
              }
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCommand(cmd, { businessParameterConfigurationId }) {
      if (["a", "b"].includes(cmd)) {
        this.dialogVisible = true;
        this.isDetail = cmd === "a";
        this.isEdit = ["a", "b"].includes(cmd);
        this.detail(businessParameterConfigurationId);
      } else if (cmd == "c") {
        this.$confirm("此操作将删除本条配置, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.handleDelete(businessParameterConfigurationId);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    handleDelete(businessParameterConfigurationId) {
      this.$axios
        .post("/acb/2.0/businessParameterConfiguration/delete", {
          data: {
            businessParameterConfigurationId,
          },
        })
        .then(({ state, desc }) => {
          if (state == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.search();
          } else {
            this.alertBox(desc);
          }
        });
    },
    closeDialogFn() {
      // this.dialogVisible = false;
      Object.assign(this.addformInline, {
        operationId: "",
        areaId: "",
        itemCode: "",
        parameterValue: "",
        parameterDescription: "",
      });
      this.exitType = "";
      this.isDetail = false;
      this.isEdit = false;
      this.isAdd = false;
    },
    closeConfigFun(...param) {
      console.log("--closeConfigFun--", param);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.search();
    },
    search: debounce(function () {
      this.$axios
        .get("/acb/2.0/businessParameterConfiguration/list", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    }, 50),
    /**
     * 重置数据
     */
    initData() {
      let { rule } = this;
      forEach(keys(rule), (key) => {
        let [item] = rule[key];
        item.required = true;
      });
      this.addformInline = {
        operationId: "",
        areaId: "",
        itemCode: "",
        parameterValue: "",
        parameterDescription: "",
      };
      this.pdaInfo = {
        warning1: true,
        warning2: true,
        minutes1: "",
        minutes2: "",
      };
    },
    /**
     * 新增
     */
    add() {
      this.initData();
      this.isAdd = true;
      this.dialogVisible = true;
    },
    /**
     * 配置
     */
    config() {
      this.configVisible = true;
      console.log("---配置---");
    },
  },
  created() {},
  mounted() {
    this.pageNum = 1;
    this.pageSize = 15;
    this.search();
    this.getTenantList();
    this.getAreaList();
    this.getConfiguration();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.conborder {
  border-top: 1px solid #C0CCDA;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }

  .pda-wrapper {
    .item-wrap {
      line-height: 50px;

      span {
        padding-left: 10px;
        color: #999;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.color_1 {
  color: #3d94dd;
}

.color_2 {
  color: #D9001B;
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.avatar-uploader-icon {
  cursor: pointer;
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.avatar {
  width: 100%;
  display: block;
}
.left10
  margin-left: 10px;
.showSpace
  white-space: pre-line;
.b-style
  white-space pre-line
  ::v-deep b
    font-weight 400
    color #16ABFF
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "config-dialog-box",
      attrs: {
        title: _vm.title,
        width:
          _vm.formData.itemCode == 20 && _vm.batchList.length
            ? "840px"
            : "584px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          attrs: {
            model: _vm.formData,
            "label-width": "110px",
            rules: _vm.rule,
          },
        },
        [
          _c(
            "el-form-item",
            { key: "areaId", attrs: { label: "城市", prop: "areaId" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    filterable: "",
                    data: _vm.areaList,
                    label: "areaName",
                    value: "areaId",
                    placeholder: "全部",
                    modifier: "trim",
                    disabled:
                      _vm.isEdit ||
                      !_vm.checkParams({ itemCode: [1, 6, 7, 12, 13, 14] }),
                  },
                  model: {
                    value: _vm.formData.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "areaId", $$v)
                    },
                    expression: "formData.areaId",
                  },
                },
                [_c("el-option", { attrs: { label: "全部", value: "0" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              key: "operationId",
              attrs: {
                label: _vm.$t("searchModule.Merchant_Name"),
                prop: "operationId",
              },
            },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    filterable: "",
                    data: _vm.tenantList,
                    label: "operationName",
                    value: "operationId",
                    placeholder: "全部",
                    modifier: "trim",
                    disabled:
                      _vm.isEdit ||
                      !_vm.checkParams({ itemCode: [1, 6, 7, 12, 13, 14] }),
                  },
                  model: {
                    value: _vm.formData.operationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "operationId", $$v)
                    },
                    expression: "formData.operationId",
                  },
                },
                [_c("el-option", { attrs: { label: "全部", value: "0" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              key: "itemCode",
              attrs: {
                label: _vm.$t("searchModule.Configuration_items"),
                prop: "itemCode",
              },
            },
            [
              _c("a-select", {
                attrs: {
                  data: _vm.getConfigDetailList,
                  label: "identifier",
                  value: "code",
                  disabled: _vm.isEdit,
                },
                on: { change: _vm.configChange },
                model: {
                  value: _vm.formData.itemCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "itemCode", $$v)
                  },
                  expression: "formData.itemCode",
                },
              }),
            ],
            1
          ),
          _vm.checkParams({ itemCode: [1, 6, 7, 8, 11, 12] }, "some")
            ? _c(
                "el-form-item",
                [
                  _c("a-radio", {
                    attrs: { disabled: _vm.isDetail, radioList: _vm.radioList },
                    model: {
                      value: _vm.formData.parameterValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parameterValue", $$v)
                      },
                      expression: "formData.parameterValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.checkParams({ itemCode: 7, parameterValue: 0 })
            ? _c(
                "el-form-item",
                [
                  _c("a-radio", {
                    attrs: {
                      disabled: _vm.isDetail,
                      radioList: [
                        { label: "0", value: "自动出场" },
                        { label: "1", value: "PDA确认出场" },
                      ],
                    },
                    model: {
                      value: _vm.exitType,
                      callback: function ($$v) {
                        _vm.exitType = $$v
                      },
                      expression: "exitType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.checkParams({ itemCode: 13 })
            ? _c("el-form-item", { staticClass: "pda-wrapper" }, [
                _c("div", { staticClass: "item-wrap" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.pdaInfo.warning1,
                            callback: function ($$v) {
                              _vm.$set(_vm.pdaInfo, "warning1", $$v)
                            },
                            expression: "pdaInfo.warning1",
                          },
                        },
                        [_vm._v("一次警示")]
                      ),
                      _c(
                        "span",
                        [
                          _vm._v("地磁信号已上报： "),
                          _c("a-input", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              valueType: "plus",
                              range: [0, 240],
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.pdaInfo.minutes1,
                              callback: function ($$v) {
                                _vm.$set(_vm.pdaInfo, "minutes1", $$v)
                              },
                              expression: "pdaInfo.minutes1",
                            },
                          }),
                          _vm._v(" 分钟后变色警示，取值范围 [ 0，240 ] "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.pdaInfo.warning2,
                            callback: function ($$v) {
                              _vm.$set(_vm.pdaInfo, "warning2", $$v)
                            },
                            expression: "pdaInfo.warning2",
                          },
                        },
                        [_vm._v("二次警示")]
                      ),
                      _c(
                        "span",
                        [
                          _vm._v("地磁信号已上报： "),
                          _c("a-input", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              valueType: "plus",
                              range: [0, 240],
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.pdaInfo.minutes2,
                              callback: function ($$v) {
                                _vm.$set(_vm.pdaInfo, "minutes2", $$v)
                              },
                              expression: "pdaInfo.minutes2",
                            },
                          }),
                          _vm._v(" 分钟后变色警示，取值范围 [ 0，240 ] "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.checkParams({ itemCode: ["", 1, 6, 7, 8, 11, 12, 13, 20] })
            ? _c(
                "el-form-item",
                {
                  key: "parameterValue",
                  staticClass: "parameter-value-label",
                  attrs: {
                    label: _vm._f("formatLabel")(_vm.itemShow),
                    prop: "parameterValue",
                  },
                },
                [
                  _vm.checkParams({ itemCode: 16 })
                    ? [
                        _c("el-time-picker", {
                          attrs: { format: "HH:mm", "value-format": "HH:mm" },
                          model: {
                            value: _vm.formData.parameterValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "parameterValue", $$v)
                            },
                            expression: "formData.parameterValue",
                          },
                        }),
                      ]
                    : [
                        _c(
                          "a-input",
                          _vm._b(
                            {
                              attrs: { disabled: _vm.isDetail },
                              model: {
                                value: _vm.formData.parameterValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "parameterValue", $$v)
                                },
                                expression: "formData.parameterValue",
                              },
                            },
                            "a-input",
                            { ..._vm.paramConfig },
                            false
                          )
                        ),
                      ],
                  _vm.checkParams(
                    { itemCode: [2, 3, 4, 5, 9, 10, 14, 15] },
                    "some"
                  )
                    ? _c("span", [_vm._v(_vm._s(_vm.getContent))])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.formData.itemCode == 20
            ? _c(
                "el-form-item",
                { attrs: { label: "批次名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入批次名称" },
                    model: {
                      value: _vm.batchName,
                      callback: function ($$v) {
                        _vm.batchName = $$v
                      },
                      expression: "batchName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "add-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "el-plain",
                          attrs: { icon: "el-icon-plus" },
                          on: { click: _vm.addBatch },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.addto")))]
                      ),
                    ],
                    1
                  ),
                  _vm.batchList.length
                    ? _c("div", { staticClass: "batch-list" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.batchList, function (item, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _vm._v(" " + _vm._s(item) + " "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el-plain",
                                    attrs: { icon: "el-icon-minus" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delBatch(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.itemCode
            ? _c("el-form-item", [
                _vm.checkParams({ itemCode: 15 })
                  ? _c(
                      "div",
                      { staticClass: "bussiness-box" },
                      _vm._l(_vm.arrearsBussiness, function (item, key) {
                        return _c(
                          "div",
                          { key: key, staticClass: "bussiness-box-item" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              },
                              [
                                _c("span", { staticClass: "checkbox-title" }, [
                                  _vm._v(_vm._s(item.identifier)),
                                ]),
                              ]
                            ),
                            item.checked
                              ? _c("span", { staticClass: "tip-item" }, [
                                  _vm._v(_vm._s(item.desc)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("span", {
                      staticClass: "content-tip",
                      domProps: { innerHTML: _vm._s(_vm.contentTip) },
                    }),
              ])
            : _vm._e(),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAddBlackFn },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!-- 配置显示弹窗 -->
    <el-dialog
      class="config-dialog-box"
      title="配置显示"
      width="700px"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form
        class="geomagnetic-pattern"
        label-width="100px"
        ref="configForm"
      >
        <el-form-item class="geomagnetic-pattern-item" label-width="20px" v-for="(item, key) in formData" :key="key">
          <el-switch v-model="item.switchType" />
          <div class="geomagnetic-pattern-box">
            <div class="title">{{ item.switchType | formatTitle(item.label) }}</div>
            <div class="config-item-box" v-if="item.switchType">
              <div class="config-item" v-for="(sub, index) in item.switchList" :key="key +'_'+ index">
                <el-checkbox v-model="sub.checked">{{ sub.label }}</el-checkbox>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="saveConfigFun">确 认</el-button>
      </span>
    </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'configDialog',
  components: {},
  props: {
    showDialog: Boolean,
    formData: [Object, Array]
  },
  data() {
    return {
      itemShow: '',
      exitTypeData: {
        exitType: ''
      }
    };
  },
  filters: {
    formatTitle(state, { ok, cancel }) {
      return state ? ok : cancel
    }
  },
  watch: {},
  computed: {
    dialogVisible: {
      get() {
        return this.showDialog
      },
      set(state) {
        this.$emit('update:showDialog', state)
      }
    }
  },
  methods: {
    handleClose() {
      this.initFormData()
      // this.$parent.closeConfigFun()
    },
    handleCancel() {
      this.dialogVisible = false
      this.initFormData()
    },
    initFormData(list = this.formData) {
      list.forEach(item => {
        let { switchList } = item
        if (switchList) {
          item.switchType = false
          this.initFormData(switchList)
        } else {
          item.checked = false
        }
      })
    },
    saveConfigFun() {
      let list = cloneDeep(this.formData.filter(({ switchType }) => switchType))
      this.handleCancel()
      let selected = []
      list.forEach(({ switchList }) => {
        let res = switchList.filter(({ checked }) => checked)
        if (res.length) selected.push(res)
      })
      console.log('--saveConfigFun--', selected)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="stylus" scoped>
  .config-dialog-box
    .geomagnetic-pattern
      .geomagnetic-pattern-item
        ::v-deep
          .el-form-item__content
            display flex
          .geomagnetic-pattern-box
            margin-left 10px
            .config-item-box
              display flex
              flex-wrap wrap
              margin-top 10px
              .config-item
                margin-right 20px
                margin-bottom 10px
                .el-checkbox__label
                  font-weight normal
                  color inherit !important
          
</style>
